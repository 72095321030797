import './ContactPage.css';

function ContactPage() {
  return (
    <section id='contact'>
        <header>
            <h1>Contact me</h1>
        </header>
    </section>
    );
}

export default ContactPage;